import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { Row } from "..";
import { ImRadioUnchecked, ImRadioChecked2 } from "react-icons/im";
import styles from "./styles.module.scss";

function Checkbox({ label, checked, onChange }: any) {
  const { primaryMain } = useContext(ThemeContext);

  return (
    <Row position="start">
      {
        checked ? 
        <ImRadioChecked2 onClick={onChange} style={{ cursor: 'pointer', color: primaryMain }} /> 
        : 
        <ImRadioUnchecked onClick={onChange}  style={{ cursor: 'pointer', color: primaryMain }} />
      }
      <p className={styles.title}>{label}</p>
    </Row>
  );
}

export default Checkbox;
