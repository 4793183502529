import { Row, Column, Spacing, Description } from "..";
import { Box, CircularProgress } from "@mui/material";

function Loader() {
  return (
    <>
      <Row width="100%" align="center" position="centered">
        <Box sx={{ display: "flex" }}>
          <CircularProgress size={20} color="inherit" />
        </Box>
      </Row>
      <Spacing size="small" />
      <Row width="100%" align="center" position="centered">
        <Description appearance="grey" bolder={true}>
          Carregando os dados
        </Description>
      </Row>
    </>
  );
}

export default Loader;
