import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import axios from "axios";
import useSWR from "swr";
import {
  Card,
  Column,
  Row,
  Modal,
  Input,
  Spacing,
  Button,
  SearchField,
  Footer,
  Navbar,
  Alert,
} from "../../components";
import { maskCep, maskReset, maskMoney } from "../../utils/mask";
import { FaMapMarkerAlt, FaChevronDown, FaCheckCircle } from "react-icons/fa";
import logo from "../../assets/logos/portal-de-saude-logo.svg";
import check from "../../assets/icons/icon-check-green.png";

const fetcher = (url) => axios.get(url).then((res) => res.data);

function Detail() {
  const { id } = useParams();
  const { data, error } = useSWR(
    `${process.env.REACT_APP_LINK_API}/packages-id/${id}`,
    fetcher
  );
  const navigate = useNavigate();
  const [cep, setCep] = useState("");
  const [product, setProduct] = useState({ amount: 0 });
  const [city, setCity] = useState("Porto Alegre");
  const [modalLocation, setModalLocation] = useState(false);
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  // eslint-disable-next-line no-unneeded-ternary
  const [userCity, setUserCity] = useState(city ? city : "Porto Alegre");

  const {
    context,
    addProduct,
    handleData,
    updatePackages,
    updateLocation,
  }: any = useContext(DataContext);

  useEffect(() => {
    if (context.products.length > 0) {
      checkProductsValue();
    }
  }, []);

  function checkProductsValue() {
    context.products.map((product) => {
      axios
        .get(`${process.env.REACT_APP_LINK_API}/packages-id/${product.id}`)
        .then((resp) => {
          // eslint-disable-next-line no-param-reassign
          product.value = resp.data.packages[0].value;
        });
    });
    handleData(context);
    updateProductData();
  }

  function addProductCart() {
    if (product.amount < 13) {
      addProduct(data.packages[0]);
      updateProductData();
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    }
  }

  function updateProductData() {
    if (data && data.packages.length > 0) {
      const index = context.products.findIndex(
        (element) => element.id === data.packages[0].id
      );
      // eslint-disable-next-line no-param-reassign
      setProduct(context.products[index]);
    }
  }

  function searchUserLocation() {
    axios
      .get(
        `${process.env.REACT_APP_LINK_API}/packages-by-regions/${maskReset(
          cep
        )}`
      )
      .then((response) => {
        if (response.data.packages.length === 0) {
          setModalErrorIsOpen(true);
        } else {
          getUserCity();
          updatePackages(response.data.packages);
        }
      })
      .finally(() => {
        setModalLocation(false);
        setCep("");
      });
  }

  function getUserCity() {
    axios
      .get(`https://viacep.com.br/ws/${maskReset(cep)}/json/`)
      .then((response) => {
        setUserCity(response.data.localidade);
        updateLocation({
          postCode: maskReset(response.data.cep),
          city: response.data.localidade,
        });
      });
  }

  return (
    <div className={styles.container}>
      {alert && (
        <Alert
          type="success"
          title="Adicionado!"
          text="O produto foi adicionado ao carrinho de compras!"
        />
      )}

      <Modal
        isOpen={modalLocation}
        title="Informe o CEP da sua região"
        description="Iremos filtrar os serviços de saúde associados a
        Conexxa disponíveis na sua região."
        footerButtonFirst="Cancelar"
        footerButtonFirstAppearance="ghost"
        onClickFooterFirst={() => setModalLocation(false)}
        footerButtonSecond="Continuar"
        footerButtonSecondAppearance="primary"
        onClickFooterSecond={() => searchUserLocation()}
        onClickClose={() => setModalLocation(false)}
      >
        <Spacing size="large" />
        <Input
          name="cep"
          type="text"
          width="100%"
          maxLength="10"
          placeholder="CEP"
          value={cep}
          onChange={(event) => {
            setCep(maskCep(event.target.value));
          }}
        />
        <Spacing size="large" />
      </Modal>

      <Modal
        isOpen={modalErrorIsOpen}
        image={error}
        imageWidth="70px"
        title="Serviço indisponível em sua região"
        description="Este pacote não está disponível para sua região. Mude a sua localização ou selecione um dos serviços disponíveis da sua região"
        onClickClose={() => setModalErrorIsOpen(false)}
      >
        <Spacing size="xxx-large" />
        <Row>
          <Column position="centered">
            <Button
              text="Ver serviços disponíveis"
              width="195px"
              onClick={() => setModalErrorIsOpen(false)}
            />
            <Spacing size="medium" />
            <Button
              text="Mudar localização"
              appearance="ghost"
              width="165px"
              onClick={() => {
                setModalErrorIsOpen(false);
                setModalLocation(true);
              }}
              iconPosition="left"
            >
              <FaMapMarkerAlt />
            </Button>
          </Column>
        </Row>
      </Modal>

      {data && data.packages ? (
        <>
          <Navbar />
          <SearchField city={city} />
          <div className={styles.content}>
            <div className={styles.contentColumn}>
              <Spacing size="large" />
              <h1>Para quem é indicado e onde devo fazer?</h1>
              <p
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: data.packages[0].indication,
                }}
              ></p>
              <Spacing size="small"></Spacing>
              <h1>Orientações gerais</h1>
              <div
                dangerouslySetInnerHTML={{ __html: data.packages[0].guideline }}
              ></div>
              <Spacing size="large"></Spacing>
              <h1>Onde realizar o exame?</h1>
              <div className={styles.partners}>
                <Spacing size="medium"></Spacing>
                <img src={logo} />
                <Spacing size="medium"></Spacing>
              </div>
              <p className={styles.text}>
                A compra do Pacote pelo Site Portal de Saúde, garante a você a
                aquisição do serviço, que deverá ser feito presencialmente em
                uma das clínicas credenciadas a rede.
              </p>
            </div>
            <div className={`${styles.contentColumn} ${styles.resume}`}>
              <Card appearance="ghost" width="100%">
                <Row>
                  <Column>
                    <h2 className={styles.title}>{data.packages[0].name}</h2>
                  </Column>
                </Row>
                <Spacing size="xx-large" />
                <ul className={styles.productDetails}>
                  {data.packages[0].exams.map((exam) => (
                    <div className={styles.attributeItem}>
                      <Column width="20px" position="centered">
                        <Spacing size="xxx-small" />
                        <FaCheckCircle size={18} color="green" />
                      </Column>
                      <Spacing size="x-small" />
                      <Column width="100%" position="start">
                        <p>
                          {exam.quantity > 0 && exam.quantity} {exam.name}
                        </p>
                      </Column>
                    </div>
                  ))}
                </ul>
                <Spacing size="xx-large"></Spacing>
                <div className={styles.amountInfos}>
                  <h1>R$ {maskMoney(Number(data.packages[0].value))}</h1>
                  <p>{data.packages[0].installments_description}</p>
                </div>
                <Spacing size="xx-large"></Spacing>
                <div className={styles.locationInfos}>
                  <p>Preço é referente a região:</p>
                  <p className={styles.location}>
                    <span onClick={() => setModalLocation(true)}>
                      {userCity} <FaChevronDown />
                    </span>
                  </p>
                </div>
                <Spacing size="xx-large"></Spacing>
                <Button
                  text="Comprar agora"
                  width="95%"
                  appearance="primary"
                  onClick={() => {
                    addProductCart();
                    navigate("/resumo");
                  }}
                ></Button>
                <Spacing size="large"></Spacing>
                <Button
                  text="Adicionar ao carrinho"
                  width="95%"
                  appearance={product.amount < 13 ? "ghost" : "disabled"}
                  onClick={addProductCart}
                />
              </Card>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default Detail;
