import { useState } from "react";
import { TbMinus, TbPlus } from "react-icons/tb";
import { Spacing } from "../../../../components";
import styles from "./styles.module.scss";

const Question = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Spacing size="xx-small" />
      <div className={styles.question}>
        <div className={styles.questionHeader}>
          <p>{title}</p>
          {isOpen ? (
            <TbMinus size={20} onClick={() => setIsOpen(!isOpen)} />
          ) : (
            <TbPlus size={20} onClick={() => setIsOpen(!isOpen)} />
          )}
        </div>
        {isOpen && (
          <div className={styles.questionContent}>
            <Spacing size="xxx-small" />
            <div dangerouslySetInnerHTML={{__html: description}} />
          </div>
        )}
      </div>
    </>
  );
};

export default Question;
