import React, { useEffect } from "react";
import { DataProvider } from "./context/DataContext";
import { ThemeProvider } from "./context/ThemeContext";
import { PageSizeProvider } from "./context/PageSizeContext";
import { HelmetProvider } from "react-helmet-async";
import { createRoot } from "react-dom/client";
import App from "./App";

// Styles Default Files
import "./styles/colors.scss";
import "./styles/global.scss";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <HelmetProvider>
    <DataProvider>
      <PageSizeProvider>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </PageSizeProvider>
    </DataProvider>
  </HelmetProvider>
);
