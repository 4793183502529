import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import axios from "axios";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { maskCep, maskReset } from "../../utils/mask";
import { DataContext } from "../../context/DataContext";
import { FaMapMarkerAlt } from "react-icons/fa";
import {
  Button,
  Input,
  Row,
  Text,
  Spacing,
  SearchField,
  Modal,
  Footer,
  Navbar,
} from "../../components";
import { MdOutlineLocationOff } from "react-icons/md";
import Loader from "../../components/Loader";
import Package from "./Package";

const fetcher = (url) => axios.get(url).then((res) => res.data);

function Product() {
  const { id } = useParams();
  const { data } = useSWR(
    `${process.env.REACT_APP_LINK_API}/packages/${id}`,
    fetcher
  );
  const [packages, setPackages] = useState([]);
  const [city, setCity] = useState("Porto Alegre");
  const [modalLocation, setModalLocation] = useState(false);
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cep, setCep] = useState("");
  const { context, updateLocation, updatePackages }: any =
    useContext(DataContext);
  const storage = JSON.parse(localStorage.getItem("@data"));

  useEffect(() => {
    setLoading(true);
    if (storage && storage.location.postCode !== "") {
      setCep(storage.location.postCode);
      searchUserLocation(storage.location.postCode);
    } else {
      setModalLocation(true);
    }
  }, []);

  function searchUserLocation(data: any) {
    axios
      .get(
        `${
          process.env.REACT_APP_LINK_API
        }/packages-by-regions-and-category/${maskReset(data)}/${id}`
      )
      .then((response) => {
        if (response.data.filteredPackages.length === 0) {
          setModalErrorIsOpen(true);
        } else {
          getUserCity(data);
          setCep(data);
          updatePackages(response.data.filteredPackages);

          const avaibleProducts = [];
          response.data.filteredPackages.map((product) => {
            if (product.package_categories_id === Number(id)) {
              avaibleProducts.push(product);
            }
          });
          setPackages([...avaibleProducts]);
          setLoading(false);
        }
      })
      .finally(() => {
        setModalLocation(false);
        setCep("");
      });
  }

  function getUserCity(data: any) {
    axios
      .get(`https://viacep.com.br/ws/${maskReset(data)}/json/`)
      .then((response) => {
        setCity(response.data.localidade);
        updateLocation({
          postCode: maskReset(response.data.cep),
          city: response.data.localidade,
        });
      });
  }

  return (
    data && (
      <div className={styles.container}>
        <Modal
          isOpen={modalLocation}
          height="400px"
          title="Informe o CEP da sua região"
          description="Iremos filtrar os serviços de saúde associados a
        Conexxa disponíveis na sua região."
          footerButtonFirst="Cancelar"
          footerButtonFirstAppearance="ghost"
          onClickFooterFirst={() => setModalLocation(false)}
          footerButtonSecond="Continuar"
          footerButtonSecondAppearance="primary"
          onClickFooterSecond={() => searchUserLocation(cep)}
          onClickClose={() => setModalLocation(false)}
        >
          <Spacing size="large" />
          <Input
            name="cep"
            type="text"
            width="100%"
            maxLength="10"
            placeholder="CEP"
            value={cep}
            onChange={(event) => {
              setCep(maskCep(event.target.value));
            }}
          />
          <Spacing size="large" />
        </Modal>

        <Modal
          isOpen={modalErrorIsOpen}
          height="500px"
          icon={<MdOutlineLocationOff size={90} color="red" />}
          title="Serviço indisponível em sua região"
          description="Este pacote não está disponível para sua região. Mude a sua
            localização ou selecione um dos serviços disponíveis da sua região"
          onClickClose={() => setModalErrorIsOpen(false)}
        >
          <Spacing size="xxx-large" />

          <Row width="100%" position="centered">
            <Button
              text="Ver serviços disponíveis"
              width="195px"
              onClick={() => setModalErrorIsOpen(false)}
            />
          </Row>
          <Spacing size="medium" />
          <Row width="100%" position="centered">
            <Button
              text="Mudar localização"
              appearance="ghost"
              width="195px"
              onClick={() => {
                setModalErrorIsOpen(false);
                setModalLocation(true);
              }}
              iconPosition="right"
            >
              <FaMapMarkerAlt />
            </Button>
          </Row>
        </Modal>

        <Navbar />
        <SearchField
          city={city}
          onClick={(event) => searchUserLocation(event)}
        />
        <div className={styles.banner}>
          <img
            src={`https://conexx.s3.amazonaws.com/${data.categories.image}`}
            className={styles.bannerBackground}
            draggable="false"
          />
        </div>
        <div className={styles.about}>
          <div className={styles.aboutContent}>
            <h1>{data.categories.name}</h1>
            <Spacing size="xx-small" />
            <p>{data.categories.description}</p>
          </div>
        </div>
        <div className={styles.packages}>
          {!loading ? (
            <>
              {packages.length > 0 ? (
                <div className={styles.packagesRow}>
                  {packages.map((elem) => (
                    <Package data={elem} />
                  ))}
                </div>
              ) : (
                <div>
                  <Text appearance="grey">
                    Nenhum pacote foi encontrado para a sua região
                  </Text>
                </div>
              )}
            </>
          ) : (
            <>
              <Spacing size="x-large" />
              <Loader />
            </>
          )}
        </div>
        <Footer />
      </div>
    )
  );
}

export default Product;
