import React, { useEffect, useState } from "react";
import { Spacing } from "../../../../components";
import axios from "axios";
import useSWR from "swr";
import { Element } from "react-scroll";
import { TbMinus, TbPlus } from "react-icons/tb";
import styles from "./styles.module.scss";
import Question from "./Question";

function FAQ() {
  const [faq, setFaq] = useState([]);

  const Mock = [
    {
      id: 1,
      title: "Quem pode usar o Cartão de Benefícios?",
      description:
        "Qualquer pessoa pode aproveitar os benefícios do Cartão de Benefícios! Sem restrições de idade ou condições de saúde, ele é ideal para famílias que querem economizar em saúde, educação e lazer, garantindo mais qualidade de vida para todos.",
    },
    {
      id: 2,
      title: "Quais tipos de serviços de saúde estão inclusos no desconto?",
      description:
        "Com o Cartão de Benefícios, você tem descontos em consultas médicas, exames, atendimento odontológico e até cirurgias eletivas, abrangendo as principais áreas da saúde!",
    },
    {
      id: 3,
      title: "Como posso solicitar o Cartão de Benefícios?",
      description:
        "Para solicitar o cartão de benefícios, é necessário seguir algumas etapas simples. Primeiro, escolha o cartão que melhor atenda às suas necessidades. Em seguida, realize o cadastro informando os dados do usuário que fará uso do produto. Após isso, efetue a compra do cartão. Uma vez aprovado, você poderá começar a desfrutar de todos os benefícios oferecidos.",
    },
    {
      id: 4,
      title: "Como tenho acesso ao produtos de Pacotes de Serviços?",
      description:
        "Para acessar os produtos dos Pacotes de Serviços, é necessário seguir algumas etapas simples. Primeiro, escolha, dentro das categorias disponíveis, o produto que melhor atenda às suas necessidades. Em seguida, realize o cadastro, informando os dados do usuário que fará uso do produto. Após isso, efetue a compra do cartão. Assim que for aprovado, você poderá começar a desfrutar de todos os benefícios oferecidos na rede credenciada.",
    },
  ];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_LINK_API}/faqs`)
      .then((response) => {
        setFaq([...response.data.faqs]);
        console.log(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <Element id="faq-section" name="faq-section">
      <div className={styles.faq}>
        <div className={styles.faqTitle}>
          <h1>Perguntas Frequentes</h1>
        </div>
        <div className={styles.faqAnswers}>
          {faq.map((faq, index) => (
            <Question title={faq.question} description={faq.answer} key={index} />
          ))}
        </div>
      </div>
    </Element>
  );
}

export default FAQ;
