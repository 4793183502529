/* eslint-disable react/jsx-no-constructed-context-values */
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

export const ThemeContext = createContext({} as any);

export function ThemeProvider({ children }) {
  function useThemeDefault() {
    const [defaultTheme, setDefaultTheme] = useState<any>({});

    useEffect(() => {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_LINK_API}/parametrization/front`,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          const theme = {
            primaryLight: response.data.designer[0].primary_light,
            primaryMain: response.data.designer[0].primary_main,
            primaryDark: response.data.designer[0].primary_dark,
            primaryBackground: response.data.designer[0].primary_background,
            title:response.data.designer[0].title,
            description: response.data.designer[0].description,
            favicon: response.data.designer[0].favicon,
            logo: response.data.designer[0].logo_light,
            bannerTitle: response.data.designer[0].banner_title,
            bannerImage: response.data.designer[0].banner_image,
            bannerSubtitle: response.data.designer[0].banner_subtitle,
            colorTextBanner: response.data.designer[0].color_text_banner
          };
          setDefaultTheme(theme);

          const root = document.documentElement;
          root?.style.setProperty("--primary-color-light", theme.primaryLight);
          root?.style.setProperty("--primary-color-main", theme.primaryMain);
          root?.style.setProperty("--primary-color-dark", theme.primaryDark);
          root?.style.setProperty(
            "--primary-color-background",
            theme.primaryBackground
          );
        })
        .catch((error) => console.error(error));
    }, []);

    return defaultTheme;
  }

  const {
    primaryLight,
    primaryMain,
    primaryDark,
    primaryBackground,
    title,
    description,
    logo,
    bannerTitle,
    bannerImage,
    bannerSubtitle,
    colorTextBanner
  } = useThemeDefault();
  return (
    <ThemeContext.Provider
      value={{
        primaryLight,
        primaryMain,
        primaryDark,
        primaryBackground,
        title,
        description,
        logo,
        bannerTitle,
        bannerImage,
        bannerSubtitle,
        colorTextBanner
      }}
    >
      <Helmet
        title={title}
        meta={[
          { name: "description", content: description },
          { name: "msapplication-TileColor", content: primaryMain },
          { name: "theme-color", content: primaryMain },
          { name: "msapplication-TileImage", content: "favicon.ico" },
        ]}
        link={[{ rel: "icon", type: "image/png", href: "favicon.ico" }]}
      />
      {children}
    </ThemeContext.Provider>
  );
}

export function useThemeContext() {
  const context = useContext(ThemeContext);

  return context;
}
