import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./styles.module.scss";
import axios from "axios";
import useSWR from "swr";
import * as Scroll from "react-scroll";
import { Element } from "react-scroll";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Row,
  Spacing,
  Text,
  SearchField,
  Footer,
  Undertitle,
  Title,
} from "../../components";

import productImage from "../../assets/woman-health-product-image.png";
import FAQ from "./components/FAQ";
import { TbVaccine } from "react-icons/tb";
import { BiDna } from "react-icons/bi";
import { IoIosArrowDropright } from "react-icons/io";
import {
  MdOutlineCoronavirus,
  MdOutlineComputer,
  MdOutlineHomeRepairService,
  MdOutlineMonitorHeart,
} from "react-icons/md";
import { RiStethoscopeLine } from "react-icons/ri";
import { HiOutlineClipboardList } from "react-icons/hi";
import { FaArrowRight } from "react-icons/fa";
import { PageSizeContext } from "../../context/PageSizeContext";
import { ThemeContext } from "../../context/ThemeContext";
import Partners from "./Partners";

const fetcher = (url) => axios.get(url).then((res) => res.data);
const ScrollLink = Scroll.Link;

function Home() {
  const navigate = useNavigate();
  const { width }: any = useContext(PageSizeContext);
  const {
    logo,
    primaryDark,
    bannerTitle,
    bannerImage,
    bannerSubtitle,
    colorTextBanner,
  }: any = useContext(ThemeContext);
  const FAQMocked = [
    {
      title: "O que é a Conexxa Saúde?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed consectetur dolor. In ex nibh, lacinia in rhoncus vel, pulvinar id ante. Quisque nulla tortor, luctus vitae tincidunt sed, semper id justo. Proin facilisis neque felis, sit amet tristique orci ornare et. Sed eu pellentesque lacus. Aliquam eu rutrum odio, ac euismod mi. Sed quis arcu a est luctus auctor. Aliquam vitae lacus non est feugiat venenatis.",
    },
    {
      title: "Onde são realizados os procedimentos?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed consectetur dolor. In ex nibh, lacinia in rhoncus vel, pulvinar id ante. Quisque nulla tortor, luctus vitae tincidunt sed, semper id justo. Proin facilisis neque felis, sit amet tristique orci ornare et. Sed eu pellentesque lacus. Aliquam eu rutrum odio, ac euismod mi. Sed quis arcu a est luctus auctor. Aliquam vitae lacus non est feugiat venenatis.",
    },
    {
      title: "Os pacotes tem prazo de validade?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed consectetur dolor. In ex nibh, lacinia in rhoncus vel, pulvinar id ante. Quisque nulla tortor, luctus vitae tincidunt sed, semper id justo. Proin facilisis neque felis, sit amet tristique orci ornare et. Sed eu pellentesque lacus. Aliquam eu rutrum odio, ac euismod mi. Sed quis arcu a est luctus auctor. Aliquam vitae lacus non est feugiat venenatis.",
    },
  ];
  const servicesMocked = [
    {
      id: 1,
      title: "Vacinas",
      description:
        "Oferecemos uma variedade de vacinas para atender adultos e crianças de todas as idades e necessidades",
      image: <TbVaccine size={40} />,
    },
    {
      id: 2,
      title: "Testes Genéticos",
      description:
        "Avaliações e procedimentos para prevenção de doenças genéticas hereditárias, personalizados para atender às suas necessidades e objetivos de saúde",
      image: <BiDna size={40} />,
    },
    {
      id: 3,
      title: "Covid",
      description:
        "Oferecemos procedimentos e cuidados especializados para ajudar na prevenção e recuperação pós-Covid, com o objetivo de ajudá-lo a alcançar uma recuperação saudável e completa",
      image: <MdOutlineCoronavirus size={40} />,
    },
    {
      id: 4,
      title: "Acompanhamento médico",
      description:
        "Conte com um profissional especializado para cuidar da sua saúde de forma personalizada e atenciosa, garantindo um acompanhamento constante e de qualidade",
      image: <RiStethoscopeLine size={40} />,
    },
    {
      id: 5,
      title: "Exames",
      description:
        "Oferecemos uma ampla variedade de exames em clínicas e laboratórios com preços acessíveis, para que você possa cuidar da sua saúde de forma completa",
      image: <MdOutlineMonitorHeart size={40} />,
    },
    {
      id: 6,
      title: "Telemedicina",
      description:
        "Realize consultas e diagnósticos médicos sem sair de casa, por meio do nosso atendimento online, e tenha a análise dos resultados dos seus exames de forma prática e conveniente",
      image: <MdOutlineComputer size={40} />,
    },
    {
      id: 7,
      title: "Saúde Mental",
      description:
        "Cuide da sua saúde mental por meio de consultas psicológicas e psiquiátricas especializadas, oferecidas por profissionais capacitados e dedicados ao seu bem-estar emocional",
      image: <HiOutlineClipboardList size={40} />,
    },
    {
      id: 8,
      title: "Check-up",
      description:
        "Realize uma ampla gama de exames e procedimentos médicos especializados, voltados para a prevenção e cuidado com a sua saúde, proporcionando maior segurança e tranquilidade para você e sua família",
      image: <MdOutlineHomeRepairService size={40} />,
    },
  ];
  const mock = [
    {
      id: 1,
      image: productImage,
      title: "Saúde Mulher - Basic",
      description:
        "Uma série de exames e procedimentos médicos visando atender exclusivamente a saúde e o bem estar da mulher.",
      productValue: 149.99,
      totalValue: 149.99,
    },
    {
      id: 2,
      image: productImage,
      title: "Saúde Mulher - Basic",
      description:
        "Uma série de exames e procedimentos médicos visando atender exclusivamente a saúde e o bem estar da mulher.",
      productValue: 149.99,
      totalValue: 149.99,
    },
    {
      id: 3,
      image: productImage,
      title: "Saúde Mulher - Basic",
      description:
        "Uma série de exames e procedimentos médicos visando atender exclusivamente a saúde e o bem estar da mulher.",
      productValue: 149.99,
      totalValue: 149.99,
    },
  ];
  const itemsMock = [
    {
      id: 1,
      image: logo,
      title: "Google 1",
    },
    {
      id: 2,
      image: logo,
      title: "Google 2",
    },
    {
      id: 3,
      image: logo,
      title: "Google 3",
    },
    {
      id: 3,
      image: logo,
      title: "Google 3",
    },
    {
      id: 3,
      image: logo,
      title: "Google 3",
    },
    {
      id: 3,
      image: logo,
      title: "Google 3",
    },
    {
      id: 3,
      image: logo,
      title: "Google 3",
    },
    {
      id: 3,
      image: logo,
      title: "Google 3",
    },
    {
      id: 3,
      image: logo,
      title: "Google 3",
    },
    {
      id: 3,
      image: logo,
      title: "Google 3",
    },
  ];
  const { data } = useSWR(
    `${process.env.REACT_APP_LINK_API}/package-categories`,
    fetcher
  );
  const partners: any = useSWR(
    `${process.env.REACT_APP_LINK_API}/partners`,
    fetcher
  );
  const [totalValue, setTotalValue] = useState(0);
  const [products, setProducts] = useState([]);
  const [city, setCity] = useState("Porto Alegre");

  const [disabledArrow, setDisabledArrow] = useState("prev");
  const [count, setCount] = useState(1);

  const carouselRef = useRef(null);
  const itemWidth = 270;

  useEffect(() => {
    let amount: any = 0;
    mock.map((elem) => {
      amount = amount + elem.productValue;
    });
    setProducts(mock);
    setTotalValue(amount);
    axios
      .get(`${process.env.REACT_APP_LINK_API}/partners`)
      .then((response) => console.log("res: ", response))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <img src={logo} alt="logo conexx saúde" draggable="false" />
        <ul>
          <ScrollLink
            to="packages-section"
            spy={true}
            smooth={true}
            duration={500}
          >
            <li>Planos e Serviços</li>
          </ScrollLink>
          <ScrollLink
            to="services-section"
            spy={true}
            smooth={true}
            duration={500}
          >
            <li>Procedimentos</li>
          </ScrollLink>
          <ScrollLink
            to="partners-section"
            spy={true}
            smooth={true}
            duration={500}
          >
            <li>Parceiros</li>
          </ScrollLink>
          <ScrollLink to="faq-section" spy={true} smooth={true} duration={500}>
            <li>Perguntas Frequentes</li>
          </ScrollLink>
        </ul>
      </div>
      <SearchField city={city} />
      <div className={styles.banner}>
        <img
          src={bannerImage}
          className={styles.bannerBackground}
          draggable="false"
        />
        <div className={styles.bannerContent}>
          <h1 style={{ color: colorTextBanner }}>{bannerTitle}</h1>
          <h5 style={{ color: colorTextBanner }}>{bannerSubtitle}</h5>
          <ScrollLink
            to="packages-section"
            spy={true}
            smooth={true}
            duration={500}
          >
            <Button text="Planos e Serviços" width="180px">
              <FaArrowRight size={15} color="white" />
            </Button>
          </ScrollLink>
        </div>
      </div>
      <Element id="packages-section" name="packages-section">
        <div className={styles.packages}>
          <div className={styles.packagesTitle}>
            <h1>Serviços para sua saúde</h1>
          </div>
          <div className={styles.packagesRow}>
            {data &&
              data.categories.map((elem, key) =>
                key === 0 ? (
                  <div className={styles.packageMain}>
                    <div className={styles.packageBanner}>
                      <img
                        src={`https://conexx.s3.amazonaws.com/${elem.image}`}
                        draggable="false"
                        className={styles.packageBannerImage}
                        style={{ width: "100%" }}
                      />
                      <div className={styles.packageContent}>
                        <Row
                          width="100%"
                          position={width <= 600 ? "centered" : "start"}
                        >
                          <Title bolder>{elem.name}</Title>
                        </Row>
                        <Spacing size="medium" />
                        <Row
                          width="100%"
                          position={width <= 600 ? "centered" : "start"}
                        >
                          <Undertitle
                            width={width <= 600 ? "100%" : "300px"}
                            position={width <= 600 ? "center" : "start"}
                            bolder
                          >
                            {elem.description}
                          </Undertitle>
                        </Row>
                        <Spacing size="x-large" />
                        <Button
                          text="Explorar"
                          appearance="ghost"
                          width="200px"
                          onClick={() => navigate(`/produto/${elem.id}`)}
                        >
                          <FaArrowRight size={15} />
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.package}>
                    <div className={styles.packageBanner}>
                      <img
                        src={`https://conexx.s3.amazonaws.com/${elem.image}`}
                        draggable="false"
                        className={styles.packageBannerImage}
                      />
                    </div>
                    <div className={styles.packageContent}>
                      <Spacing size="x-large" />
                      <Row position="start">
                        <Undertitle bolder={true} appearance="default">
                          {elem.name}
                        </Undertitle>
                      </Row>
                      <Spacing size="medium" />
                      <Text appearance="grey" width="100%" position="start">
                        {elem.description}
                      </Text>
                    </div>
                    <div className={styles.packageFooter}>
                      <Button
                        text="Explorar"
                        appearance="ghost"
                        width="150px"
                        onClick={() => navigate(`/produto/${elem.id}`)}
                      >
                        <FaArrowRight size={15} />
                      </Button>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </Element>
      <Element id="services-section" name="services-section">
        <div className={styles.services}>
          <div className={styles.servicesHeader}>
            <h1>Nossos serviços e procedimentos</h1>
          </div>
          <Spacing size="large" />
          <div className={styles.servicesContent}>
            <div className={styles.servicesRow}>
              {servicesMocked.map((service) => (
                <div className={styles.service} key={service.id}>
                  <div className={styles.serviceImage}>{service.image}</div>
                  <p className={styles.serviceTitle}>{service.title}</p>
                  <p className={styles.serviceDescription}>
                    {service.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Element>
      <Partners />
      <FAQ />
      <Footer />
    </div>
  );
}

export default Home;
