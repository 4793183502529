import React, { useEffect, useState } from "react";
import { Undertitle } from "../../../../components";
import Amount from "../Amount";
import { maskMoney } from "../../../../utils/mask";
import { FaRegTrashAlt } from "react-icons/fa";
import styles from "./styles.module.scss";

function Product({
  image,
  title,
  amount,
  description,
  productValue,
  totalValue,
  onClickDelete,
  addProduct,
  subsProduct,
}) {
  const [amounts, setAmount] = useState(amount);
  const [value, setValue] = useState<any>(totalValue);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    setValue(parseFloat(productValue) * amounts);
  }, []);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, [window.innerWidth]);

  function addAmount() {
    if (amounts < 13) {
      const calc = parseFloat(productValue) * (amounts + 1);
      setValue(calc);
      setAmount(amounts + 1);
      addProduct(calc);
    }
  }

  function subsAmount() {
    if (amounts > 1) {
      const calc = parseFloat(productValue) * (amounts - 1);
      setValue(calc);
      setAmount(amounts - 1);
      subsProduct(calc);
    } else {
      setValue(parseFloat(productValue));
      setAmount(1);
    }
  }

  return (
    <>
      {width >= 1000 ? (
        <div className={styles.container}>
          <div className={`${styles.column} ${styles.start}`}>
            <Undertitle bolder={true} position="start">
              {title}
            </Undertitle>
          </div>
          <div className={`${styles.column} ${styles.center}`}>
            <Undertitle bolder={true} appearance="primary">
              R${maskMoney(parseFloat(productValue))}
            </Undertitle>
          </div>
          <div className={styles.column}>
            <Amount
              amount={amounts}
              addAmount={addAmount}
              subsAmount={subsAmount}
            />
          </div>
          <div className={styles.column}>
            <Undertitle bolder={true} appearance="primary">
              R${maskMoney(parseFloat(value))}
            </Undertitle>
          </div>
          <div className={styles.column}>
            <p onClick={onClickDelete}>
              <FaRegTrashAlt />
            </p>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={`${styles.column} ${styles.start}`}>
              <img src={image} alt={title} />
            </div>
            <div className={`${styles.column} ${styles.end}`}>
              <Undertitle bolder={true} position="centered">
                {title}
              </Undertitle>
            </div>
            <div
              className={`${styles.column} ${styles.end}`}
              style={{ width: "20px" }}
            >
              <p onClick={onClickDelete}>
                <FaRegTrashAlt />
              </p>
            </div>
          </div>
          <div className={styles.row}>
            <div className={`${styles.column} ${styles.start}`}>
              <p>Preço</p>
            </div>
            <div className={`${styles.column} ${styles.end}`}>
              <Undertitle bolder={true} appearance="primary">
                R${maskMoney(parseFloat(productValue))}
              </Undertitle>
            </div>
          </div>
          <div className={styles.row}>
            <div className={`${styles.column} ${styles.start}`}>
              <p>Quantidade</p>
            </div>
            <div className={`${styles.column} ${styles.end}`}>
              <Amount
                amount={amounts}
                addAmount={addAmount}
                subsAmount={subsAmount}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Product;
