import { Element } from "react-scroll";
import styles from "./styles.module.scss";
import axios from "axios";
import useSWR from "swr";
import { useContext, useEffect, useRef, useState } from "react";
import { IoIosArrowDropright } from "react-icons/io";
import { Spacing } from "../../components";
import { useAnimationFrame } from "framer-motion";
import { PageSizeContext } from "../../context/PageSizeContext";
import { ThemeContext } from "../../context/ThemeContext";

const Partners = () => {
  const carouselRef = useRef(null);
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const [disabledArrow, setDisabledArrow] = useState("prev");
  const [partners, setPartners] = useState([]);
  const [count, setCount] = useState(1);
  const itemWidth = 270;
  const { width }: any = useContext(PageSizeContext);
  const { logo, primaryDark }: any = useContext(ThemeContext);

  //   const items = [...partners.data, ...partners.data];


//   const handleNext = () => {
//     carouselRef.current.scrollLeft += itemWidth;

//     const itemsDisplayed = Math.floor(
//       carouselRef.current.offsetWidth / itemWidth
//     );

//     const isLastItem = partners.length - count === itemsDisplayed;
//     setDisabledArrow(isLastItem ? "next" : null);
//     setCount((prev) => prev + 1);
//   };

//   const handlePrev = () => {
//     carouselRef.current.scrollLeft -= itemWidth;
//     setCount((prev) => prev - 1);
//     setDisabledArrow(count === 1 ? "prev" : null);
//   };

  // Função para calcular o movimento contínuo
  useAnimationFrame((t) => {
    if (carouselRef.current) {
      const width = carouselRef.current.scrollWidth / 3; // Comprimento de uma lista clonada
      const x = (t * 0.04) % width; // Calcula o deslocamento com base no tempo
      carouselRef.current.style.transform = `translateX(-${x}px)`; // Move os itens
    }
  });

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_LINK_API}/partners`)
    .then((response) => setPartners([...response.data, ...response.data, ...response.data]))
    .catch((error) => console.error(error));
  }, []);

  return (
    <Element id="partners-section" name="partners-section">
      <div className={styles.partners}>
        <div className={styles.partnersHeader}>
          <h1>Nossos parceiros</h1>
          {/* {width >= 900 && (
            <div className={styles.arrows}>
              <button
                className={styles.arrow}
                onClick={handlePrev}
                aria-label="Previous"
                disabled={disabledArrow === "prev"}
              >
                <IoIosArrowDropright size={30} color={primaryDark} />
              </button>
              <button
                className={styles.arrow}
                onClick={handleNext}
                aria-label="Next"
                disabled={disabledArrow === "next"}
              >
                <IoIosArrowDropright size={30} color={primaryDark} />
              </button>
            </div>
          )} */}
        </div>
        <Spacing size="large" />
        <div className={styles.partnersContent}>
          <div className={styles.carousel} ref={carouselRef}>
            {partners.map((item, key) => (
              <div className={styles.image} key={key}>
                <img
                  src={`https://conexx.s3.amazonaws.com/${item.image}`}
                  alt={item.name}
                  draggable={false}
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        </div>
        {/* {width < 900 && (
          <div className={styles.partnersHeader}>
            <Spacing size="large" />
            <div className={styles.arrows}>
              <button
                className={styles.arrow}
                onClick={handlePrev}
                aria-label="Previous"
                disabled={disabledArrow === "prev"}
              >
                <IoIosArrowDropright size={30} />
              </button>
              <button
                className={styles.arrow}
                onClick={handleNext}
                aria-label="Next"
                disabled={disabledArrow === "next"}
              >
                <IoIosArrowDropright size={30} />
              </button>
            </div>
          </div>
        )} */}
      </div>
    </Element>
  );
};

export default Partners;
