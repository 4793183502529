import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import styles from "./styles.module.scss";
import { BsXLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();
  const { logo }: any = useContext(ThemeContext);

  return (
    <div className={styles.container}>
      <img src={logo} alt="Logo" onClick={() => navigate("/")} />
      <BsXLg onClick={() => navigate("/")} />
    </div>
  );
}

export default Navbar;
