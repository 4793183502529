import React from "react";
import styles from "./styles.module.scss";
import { Description, Spacing } from "..";
import * as Scroll from "react-scroll";
import dayjs from "dayjs";

import logo from "../../assets/logos/portal-de-saude-logo.svg";
import { TbBrandInstagram, TbBrandLinkedin, TbBrandYoutube } from "react-icons/tb";
import { RiFacebookCircleLine } from "react-icons/ri";
import { MdOutlineEmail, MdOutlinePhoneAndroid } from "react-icons/md";

function Footer() {
  const ScrollLink = Scroll.Link;

  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={`${styles.footerColumn} ${styles.start}`}>
          <img src={logo} className={styles.logo} draggable="false" />
          <Spacing size="small" />
          <p>
            Oferecemos soluções <br />
            eficientes e econômicas para <br />
            cuidar da sua saúde com um <br />
            novo olhar.
          </p>
          <Spacing size="small" />
          <div className={styles.footerSocial}>
            <TbBrandLinkedin size={20} />
            <TbBrandYoutube size={20} />
            <TbBrandInstagram size={20} />
            <RiFacebookCircleLine size={20} />
          </div>
        </div>
        <div className={`${styles.footerColumn} ${styles.centered}`}>
          <div>
            <ScrollLink
              to="packages-section"
              spy={true}
              smooth={true}
              duration={500}
            >
              <p className={styles.footerLink}>Planos e Serviços</p>
            </ScrollLink>
            <Spacing size="large" />
            <ScrollLink
              to="services-section"
              spy={true}
              smooth={true}
              duration={500}
            >
              <p className={styles.footerLink}>Procedimentos</p>
            </ScrollLink>
            <Spacing size="large" />
            <ScrollLink
              to="faq-section"
              spy={true}
              smooth={true}
              duration={500}
            >
              <p className={styles.footerLink}>Perguntas Frequentes</p>
            </ScrollLink>
          </div>
        </div>
        <div className={`${styles.footerColumn} ${styles.centered}`}>
          <div>
            <p className={styles.footerLink}>
              <MdOutlineEmail size={18} /><Spacing size="xx-small" /> contato@startaideia.com.br
            </p>
            <Spacing size="small" />
            <p className={styles.footerLink}>
              <MdOutlinePhoneAndroid size={18} /><Spacing size="xx-small" /> +55 (51) 99387-2616
            </p>
          </div>
        </div>
      </div>
      <Spacing size="xxx-large" />
      <div className={styles.footerContent}>
        <div className={`${styles.footerColumn} ${styles.centered}`}>
          <Description appearance="white">
            {dayjs().year()} Portal de Saúde - Todos os direitos reservados
          </Description>
          <Spacing size="xx-small" />
          <p>
            Desenvolvido por{" "}
            <b
              className={styles.company}
              onClick={() => window.open("https://startaideia.com.br/").focus()}
            >
              Startaideia
            </b>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
