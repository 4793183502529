import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { maskMoney } from "../../utils/mask";
import { DataContext } from "../../context/DataContext";
import Product from "./components/Product";
import {
  Layout,
  Spacing,
  Description,
  Row,
  Divider,
  Modal,
  Input,
  Button,
  Column,
  Text,
} from "../../components";
import { FaArrowRight } from "react-icons/fa";
import { BiSend } from "react-icons/bi";

function Summary() {
  const navigate = useNavigate();
  const [totalValue, setTotalValue] = useState(0);
  const { context, handleData, deleteProduct }: any = useContext(DataContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [coupom, setCoupom] = useState({
    search: "",
    status: null,
    name: "",
    type: "",
    discount_value: "",
    discount_percentage: "",
  });
  const [coupon, setCoupon] = useState();

  useEffect(() => {
    checkProductsValue();
    handleTotalValue();
  }, []);

  function handleTotalValue() {
    let amount: any = 0;
    const storage = JSON.parse(localStorage.getItem("@data"));
    storage.products.map((elem) => {
      amount = amount + parseFloat(elem.value) * elem.amount;
    });
    setTotalValue(amount);
  }

  function checkProductsValue() {
    context.products.map((product) => {
      axios
        .get(`${process.env.REACT_APP_LINK_API}/packages-id/${product.id}`)
        .then((resp) => {
          // eslint-disable-next-line no-param-reassign
          product.value = resp.data.packages[0].value;
          // eslint-disable-next-line no-param-reassign
          product.totalValue = parseFloat(resp.data.packages[0].value);
        })
        .finally(() => {
          handleData(context);
        });
    });
  }

  function addProduct(id: number, value: any) {
    const index = context.products.findIndex((element) => element.id === id);
    context.products[index].amount += 1;
    context.products[index].totalValue = value.toFixed(2);
    handleData({ products: context.products });

    updateTotalValue();
  }

  function subsProduct(id: number, value: any) {
    const index = context.products.findIndex((element) => element.id === id);
    context.products[index].amount -= 1;
    context.products[index].totalValue = value.toFixed(2);
    handleData({ products: context.products });

    updateTotalValue();
  }

  function updateTotalValue() {
    let amount: any = 0;
    context.products.map((elem) => {
      amount = amount + parseFloat(elem.value) * elem.amount;
    });
    setTotalValue(amount);
  }

  function handlePayment() {
    navigate("/cadastro");
  }

  return (
    <Layout navbar={true} width="90%">
      <Modal
        isOpen={modalIsOpen}
        title="Insira um Cupom"
        description="Cada produto pode ter apenas um cupom aplicado."
        footerButtonFirst="Cancelar"
        footerButtonFirstAppearance="ghost"
        footerButtonFirstWidth="74px"
        onClickFooterFirst={() => setModalIsOpen(false)}
        footerButtonSecond="Aplicar"
        footerButtonSecondAppearance="primary"
        footerButtonSecondWidth="74px"
        onClickFooterSecond={() => setModalIsOpen(false)}
        onClickClose={() => setModalIsOpen(false)}
      >
        <Spacing size="large" />
        <Input
          name="coupon"
          type="text"
          width="100%"
          placeholder="Inserir Código do cupom"
          value={coupon}
          onChange={(event) => {
            setCoupon(event.target.value);
          }}
        />
        <Spacing size="large" />
      </Modal>

      <Spacing size="xx-large" />
      {window.innerWidth > 1000 ? (
        <div className={styles.tableHeader}>
          <div className={`${styles.column} ${styles.start}`}>
            <p>Plano</p>
          </div>
          <div className={`${styles.column} ${styles.center}`}>
            <p>Preço</p>
          </div>
          <div className={styles.column}>
            <p>Quantidade</p>
          </div>
          <div className={styles.column}>
            <p>Total</p>
          </div>
          <div className={styles.column}></div>
        </div>
      ) : (
        <div className={styles.tableHeader}>
          <div className={`${styles.column}`}>
            <p>Plano</p>
          </div>
          <div className={styles.column}></div>
        </div>
      )}
      <div className={styles.container}>
        {context.products.length > 0 ? (
          context.products.map((product) => (
            <>
              <Spacing size="large" />
              <Product
                image={`https://conexx.s3.amazonaws.com/${product.image}`}
                title={product.name}
                description={product.description}
                productValue={product.value}
                amount={product.amount}
                totalValue={product.totalValue}
                onClickDelete={() => {
                  deleteProduct(product.id);
                  handleTotalValue();
                }}
                addProduct={(event) => addProduct(product.id, event)}
                subsProduct={(event) => subsProduct(product.id, event)}
              />
            </>
          ))
        ) : (
          <>
            <Spacing size="large" />
            <Row>
              <Description appearance="grey">
                Nenhum produto foi adicionado ao carrinho
              </Description>
            </Row>
          </>
        )}
        <Spacing size="medium" />
        {context.products.length > 0 && (
          <>
            <Divider />
            <Spacing size="x-large" />
            <Row position="end">
              <div className={styles.summary}>
                <h1>Detalhes do preço</h1>
                {context.products.length > 0 ? (
                  context.products.map((product) => (
                    <div className={styles.valueDisplay}>
                      <h5>Preço ({product.amount} item)</h5>
                      <h5>R$ {maskMoney(Number(product.totalValue))}</h5>
                    </div>
                  ))
                ) : (
                  <p></p>
                )}
                <Spacing size="xxx-large" />
                <div className={styles.row}>
                  <Text>Cupom de desconto</Text>
                  <Spacing size="small" />
                  <Input
                    name="city"
                    type="text"
                    width="155px"
                    label=""
                    value={coupom.search}
                    onChange={(event) =>
                      setCoupom({ ...coupom, search: event.target.value })
                    }
                  />
                  <div
                    className={styles.button}
                    onClick={() => {
                      axios
                        .post(
                          `${process.env.REACT_APP_LINK_API}/coupons/couponbyname`,
                          {
                            name: coupom.search,
                          }
                        )
                        .then((response) => {
                          setCoupom({ ...response.data.coupon, status: 1 });
                          console.log(response.data.coupon);
                        })
                        .catch((error) => {
                          console.error(error);
                          setCoupom({
                            ...coupom,
                            status: 0,
                            name: "",
                            type: "",
                            discount_value: "",
                          });
                        });
                    }}
                  >
                    <BiSend size={20} color="white" />
                  </div>
                </div>
                <Spacing size="x-small" />
                {coupom.status === 1 && (
                  <Row width="100%" position="end">
                    <i style={{ color: "green", fontSize: "12px" }}>
                      Cupom Inserido!
                    </i>
                  </Row>
                )}
                {coupom.status === 0 && (
                  <Row width="100%" position="end">
                    <i style={{ color: "red", fontSize: "12px" }}>
                      Cupom inválido!
                    </i>
                  </Row>
                )}
                <Spacing size="large" />
                {coupom.name && (
                    <div className={styles.valueDisplay}>
                      <h5
                        style={{
                          color: coupom.status === 1 ? "green" : "black",
                          fontWeight: "bolder",
                        }}
                      >
                        Desconto
                      </h5>
                      <h5
                        style={{
                          color: coupom.status === 1 ? "green" : "black",
                        }}
                      >
                        - R$
                        {coupom.type === "P"
                          ? maskMoney(
                              totalValue *
                                (Number(coupom.discount_percentage) / 100)
                            )
                          : maskMoney(Number(coupom.discount_value))}
                      </h5>
                    </div>
                  )}
                <div className={styles.valueDisplay}>
                  <h1>Total:</h1>
                  <h1>
                    R${" "}
                    {coupom.type === "P"
                      ? maskMoney(
                          totalValue *
                            (Number(coupom.discount_percentage) / 100)
                        )
                      : maskMoney(totalValue - Number(coupom.discount_value))}
                  </h1>
                </div>
                <Spacing size="medium" />
                <Row position="centered">
                  <Button
                    text="Ir para pagamento"
                    size="lg"
                    width="100%"
                    appearance={
                      context.products.length > 0 ? "primary" : "disabled"
                    }
                    onClick={() =>
                      context.products.length > 0 ? navigate("/cadastro") : ""
                    }
                  >
                    <FaArrowRight size={15} color="white" />
                  </Button>
                </Row>
              </div>
            </Row>
          </>
        )}
      </div>
      <Spacing size="xxx-large" />
    </Layout>
  );
}

export default Summary;
