import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageSizeContext } from "../../../context/PageSizeContext";
import { Spacing, Row, Button, Title, Description } from "../../../components";
import { maskMoney } from "../../../utils/mask";
import {
  FaArrowRight,
  FaCheckCircle,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import styles from "../styles.module.scss";

function Package({ data }) {
  const navigate = useNavigate();
  const { width }: any = useContext(PageSizeContext);
  const [showText, setShowText] = useState(false);

  return (
    <div className={styles.package}>
      <div className={styles.packageContent}>
        <Spacing size="small" />
        <h1>{data.name}</h1>
        <Spacing size={width >= 900 ? "large" : "small"} />

        {width >= 900 && (
          <>
            <div className={styles.value}>
              <Title bolder>R$ {maskMoney(Number(data.value))}</Title>
              <Spacing size="small" />
              <Description appearance="grey2">
                {data.installments_description}
              </Description>
            </div>
            <Spacing size="xxx-large" />
            <Row position="centered">
              <Button
                text="Começar"
                width="100%"
                onClick={() => navigate(`/detalhe/${data.id}`)}
              >
                <FaArrowRight size={15} color="white" />
              </Button>
            </Row>
          </>
        )}
        <Spacing size="large" />
        <div
          className={styles.attributes}
          style={{
            maxHeight: showText || width <= 900 ? "100%" : "260px",
          }}
        >
          {data.exams.map((exam) => (
            <div className={styles.attributeItem}>
              <FaCheckCircle size={18} color="green" />
              <p>
                {exam.quantity > 0 && exam.quantity} {exam.name}
              </p>
            </div>
          ))}
        </div>
        {data.exams.length > 5 && (
          <>
            <Spacing size="large" />
            {!showText ? (
              <FaChevronDown
                className={styles.dropDownIcon}
                size={20}
                color="#909eab"
                style={{ cursor: "pointer" }}
                onClick={() => setShowText(true)}
              />
            ) : (
              <FaChevronUp
                className={styles.dropDownIcon}
                size={20}
                color="#909eab"
                style={{ cursor: "pointer" }}
                onClick={() => setShowText(false)}
              />
            )}
            <Spacing size="large" />
          </>
        )}
        {width < 900 && (
          <>
            <Spacing size="large" />
            <div className={styles.value}>
              <h1>R$ {maskMoney(Number(data.value))}</h1>
              <p>{data.installments_description}</p>
            </div>
            <Spacing size="small" />
            <Row position="centered">
              <Button
                text="Começar"
                width="100%"
                onClick={() => navigate(`/detalhe/${data.id}`)}
              >
                <FaArrowRight size={15} color="white" />
              </Button>
            </Row>
            <Spacing size="large" />
          </>
        )}
      </div>
    </div>
  );
}

export default Package;
