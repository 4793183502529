import React, { useContext } from "react";
import { BsXLg } from "react-icons/bs";
import { Title, Text, Spacing, Button } from "..";
import styles from "./styles.module.scss";
import { PageSizeContext } from "../../context/PageSizeContext";

function Modal({
  isOpen = false,
  width = "500px",
  height,
  icon,
  image,
  imageWidth = "100%",
  title,
  description,
  children,
  onClickClose,
  footerButtonFirst,
  footerButtonFirstAppearance = "primary",
  onClickFooterFirst,
  footerButtonFirstLoading = false,
  footerButtonSecond,
  footerButtonSecondAppearance = "ghost",
  onClickFooterSecond,
  footerButtonSecondLoading = false,
}: any) {
  const { width: pageWidth }: any = useContext(PageSizeContext);

  return (
    isOpen && (
      <div className={styles.container} onClick={onClickClose}>
        <div
          className={styles.modal}
          style={{ width: width && width, height: height && height }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.content}>
            {onClickClose && (
              <div className={styles.close}>
                <BsXLg className={styles.icon} onClick={onClickClose} />
              </div>
            )}
            <div className={styles.header}>
              {image && (
                <img
                  src={image}
                  alt={title}
                  style={{ width: imageWidth }}
                  className={styles.image}
                />
              )}
              {icon && icon}
              <Spacing size="xx-small" />
              <Title bolder={true} appearance="primary">
                {title}
              </Title>
              <Spacing size="xx-small" />
              <div className={styles.description}>
                <Text>{description}</Text>
              </div>
              <Spacing size="medium" />
            </div>
            <Spacing size="large" />
            <div className={styles.body}>{children}</div>
            {footerButtonFirst || footerButtonSecond ? (
              <div className={styles.footer}>
                {footerButtonFirst && (
                  <Button
                    width="fit-content"
                    size="sm"
                    appearance={footerButtonFirstAppearance}
                    text={footerButtonFirst}
                    onClick={onClickFooterFirst}
                    loading={footerButtonFirstLoading}
                  />
                )}
                {footerButtonSecond && (
                  <Button
                    width="fit-content"
                    size="sm"
                    appearance={footerButtonSecondAppearance}
                    text={footerButtonSecond}
                    onClick={onClickFooterSecond}
                    loading={footerButtonSecondLoading}
                  />
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    )
  );
}

export default Modal;
